import BasePlugin from '../BasePlugin'

export default class ExportAddressProgramReport extends BasePlugin {
  async execute () {
    let model = this.context.getModel()
    this.context.$http({
      method: 'post',
      url: `${this.context.$config.api}/registryservice/plugins/execute/ExportAddressProgramReportAsyncCommand`,
      data: {
        approve_status_ids: model.approveStatus,
        unpack_status_ids: model.unpackStatus,
        start_date: model.dateStart,
        end_date: model.dateEnd,
        groups: model.groups,
        scenarios_id: model.scenario,
        cfo: model.cfo,
        sheets: model.sheets,
        project_scenario: parseInt(model.project_scen),
        async: 'true'
      }
    }).then((response) => {
      this.context.$msgbox.close()
      this.context.$msgbox({
        type: 'info',
        message: 'Выгрузка шаблона поставлена в очередь. По завершении получите уведомление'
      })
    })
  }
}
